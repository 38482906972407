* {
    --card-width: calc(42.5 * var(--vw));
}

.board-info-wrapper {
    position: relative;
    width: calc(90 * var(--vw));
    /* margin: 0 calc(5 * var(--vw)); */
    height: calc(74.5 * var(--vw));
}

.timer-board-info-wrapper {
    height: calc(82 * var(--vw));
}

.board-info-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.board-info-container {
    margin-bottom: calc(5 * var(--vw));
    /* padding: 0 calc(5 * var(--vw)); */
}

.board-info-left {
    padding: calc(2 * var(--vw));
    width: var(--card-width);
    float: left;
    margin-left: 0;
    margin-right: 1.5%;
}

.board-info-right {
    padding: calc(2 * var(--vw));
    width: var(--card-width);
    float: right;
    justify-content: right;
    margin-left: 1.5%;
    margin-right: 0;
}

.board-info .text {
    font-size: calc(5 * var(--vw));
}

.long-container {
    /* margin: 0 calc(5 * var(--vw)); */
    margin-bottom: calc(5 * var(--vw));
    padding: calc(1.5 * var(--vw));
}

.centered-text {
    text-align: center;
}

.settings-text {
    font-size: calc(4 * var(--vw)) !important;
}

.timer-container-left {
    display: flex;
}

pre {
    margin-top: inherit;
    margin-bottom: inherit;
    font-size: inherit;
}

.timer-container-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
}

.header {
    text-decoration: underline;
    margin-bottom: calc(0.45 * var(--vw));
}

.settings-container {
    position: relative;
    margin: calc(5 * var(--vw)) 0;
    height: calc(68.4 * var(--vw));
    padding: calc(3 * var(--vw));
    background-color: var(--bg-color-0);
}

.timer-settings-container {
    height: calc(74.4 * var(--vw));
}

/* Prevents user from interacting with board in settings */
.game-blocker {
    position: absolute;
    width: 100%;
    height: calc(90 * var(--vw));
    z-index: 100;
}

.done-button {
    width: calc(19 * var(--vw));
    height: calc(8.2 * var(--vw));
    font-size: calc(3.5 * var(--vw));
}

.bottom-right-button-container {
    position: absolute;
    bottom: calc(3 * var(--vw)); /* match padding of container */
    right: calc(3 * var(--vw)); /* match padding of container */
}