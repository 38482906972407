.endgame-bkgd {
    display: inherit;
    visibility: visible;
    z-index: 10;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: var(--board-color);
    border-radius: var(--border-radius);
    opacity: 0.8;

    animation: appear-subtle 200ms ease;
    animation-fill-mode: backwards;
}

.endgame-msg {
    display: inherit;
    visibility: visible;
    z-index: 10;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: calc(90 * var(--vw));
    text-align: center;
    font-size: calc(6.3 * var(--vw));
    font-weight: bold;

    animation: appear 200ms ease;
    animation-fill-mode: backwards;
}

.game-msg-start {
    width: calc(27 * var(--vw));
    height: calc(11 * var(--vw));
    font-size: calc(4 * var(--vw));
    font-weight: normal;
}

.game-msg {
    opacity: 1;
}

.game-msg-button {
    border-radius: var(--border-radius-small);
}
