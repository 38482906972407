
.tile-container {
    position: absolute;
    z-index: 2;
}

.tile {
    width: calc(19.26 * var(--vw));
    height: calc(19.26 * var(--vw));
    float: left;
    position: absolute;

    -webkit-transition: -webkit-transform var(--animation_duration) ease-in;
    -moz-transition: -moz-transform var(--animation_duration) ease-in;
    transition: transform var(--animation_duration) ease-in;
}

.tile-inner {

    border-radius: var(--border-radius-small);

    width: calc(19.26 * var(--vw));
    height: calc(19.26 * var(--vw));
    /* Blur radius, spread radius */
    --shadow-size: 0 0 calc(5 * var(--vw)) calc(1.5 * var(--vw));

    position: relative;
    overflow: hidden;
}

.tile-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.tile-layer.white {
    background-color: #ffffff;
}

.tile-text {
    line-height: calc(19.26 * var(--vw));
    text-align: center;
    font-weight: bold;
    font-size: calc(10.8 * var(--vw));
    color: var(--text-color);
    z-index: 2;
}

/* Tile deletion object */
.deleted {
    animation: delete-after-move var(--animation_duration);
    visibility: hidden;
}

.tile-new .tile-inner {
    animation: appear var(--appear_duration) ease-in-out var(--appear_delay);
    animation-fill-mode: backwards;
}

/* Tile-Specific */
.tile-layer.tile-2 {
    opacity: 0.6;
}

.tile-layer.tile-4 {
    opacity: 0.7;
}

.tile-layer.tile-8 {
    opacity: 0.8;
}

.tile-layer.tile-16 {
    opacity: 0.9;
}

.tile-layer.tile-32 {
    opacity: 0.95;
}

.tile-inner.tile-32 {
    box-shadow: var(--shadow-size) rgba(var(--current-color-values), 0.4);
}

.tile-layer.tile-64 {
    opacity: 1;
}

.tile-inner.tile-64 {
    box-shadow: var(--shadow-size) rgba(var(--current-color-values), 0.6);
}

.tile-layer.tile-128 {
    opacity: 1;
}

.tile-inner.tile-128 {
    box-shadow: var(--shadow-size) rgba(var(--current-color-values), 0.8);
}