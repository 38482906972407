.toggle-name {
    opacity: 1 !important;
    /* font-weight: 500; */
}


.bottom-buffer {
    margin-bottom: calc(5 * var(--vw));
}
.settings-toggle {
    margin-bottom: calc(4 * var(--vw));
}

.settings-toggle .btn {
    font-size: calc(2.9 * var(--vw));
}