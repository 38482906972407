@keyframes enter-left {
    0% { transform: translateX(-100%); opacity: 0; }
    /* 25% { transform: translateX(-100%); opacity: 0; } */
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes enter-right {
    0% { transform: translateX(100%); opacity: 0; }
    /* 25% { transform: translateX(100%); opacity: 0; } */
    100% { transform: translateX(0); opacity: 1; }
}

@keyframes exit-left {
    0% { transform: translateX(0); opacity: 1; }
    /* 25% { transform: translateX(0); opacity: 1; } */
    100% { transform: translateX(-100%); opacity: 0; }
}

@keyframes exit-right {
    0% { transform: translateX(0); opacity: 1; }
    /* 25% { transform: translateX(0); opacity: 1; } */
    100% { transform: translateX(100%); opacity: 0; }
}

.slide-window-container {
    position: relative;
    width: calc(90 * var(--vw));
    margin: calc(5 * var(--vw));

    height: calc(96 * var(--vh));
    overflow-x: hidden;
}

/* Hack needed to stop scrolling on ios */
.slide-window-container-ios {
    height: calc(85 * var(--vh));
}

.slide-window {
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 300ms;
    animation-fill-mode: forwards;

    width: 100%;
}

.enter-left {
    animation-name: enter-left;
}

.enter-right {
    animation-name: enter-right;
}

.exit-left {
    animation-name: exit-left;
}

.exit-right {
    animation-name: exit-right;
}

.hidden {
    transform: translateX(100%);
    opacity: 0;
}