.pop-box {
    position: relative;
    overflow: visible;

    --box-color: var(--current-color);
    --offset-size: calc(1 * var(--vw));
    --box-border-radius: var(--border-radius-small);
    --animation-duration: 150ms;
    --animation-duration-half: 75ms;
}

.pop-box-large {
    --offset-size: calc(1.5 * var(--vw));
    --box-border-radius: calc(2 * var(--vw));
    --animation-duration: 200ms;
    --animation-duration-half: 100ms;
}
/* Replaced with transition */
/* .selected {
    animation-name: pop;
    animation-duration: 150ms;
} */

.disabled.selected {
    animation: pop-easy var(--animation-duration) ease-out;
}

.pop-box.accent {
    --box-color: var(--accent-color);
}

.pop-box-bottom {
    position: absolute;
    top: var(--offset-size);
    left: var(--offset-size);
    background-color: var(--box-color);
    border-radius: var(--box-border-radius);
}

.pop-box-top {
    position: absolute;
    top: 0;
    left: 0;

    background-color: var(--bg-color-1);
    border-color: var(--box-color);
    border-style: solid;
    border-width: calc(0.2 * var(--vw));
    border-radius: var(--box-border-radius);
    transition: top var(--animation-duration-half) ease-in, left var(--animation-duration-half) ease-in;
}

.selected .pop-box-top {
    transition: top var(--animation-duration-half) ease-out, left var(--animation-duration-half) ease-out;
    top: var(--offset-size);
    left: var(--offset-size);
}

.disabled .pop-box-top {
    top: 0;
    left: 0;
}

.pop-box-top.dark-background {
    background-color: var(--bg-color-0);
}
