/* animations */
@import "Animation-Tile.css";


.game-container {
    margin: calc(2.7 * var(--vw)) 0;
    position: relative;

    border-width: var(--game-border-width);
    border-radius: var(--border-radius);
    padding: calc(2.7 * var(--vw) - var(--game-border-width));
    width: calc(90 * var(--vw));
    height: calc(90 * var(--vw));

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    cursor: default;
    -ms-touch-action: none;
    touch-action: none;

    background: var(--board-color);
}

.outline {
    border-style: solid;
    transition: border-color calc(var(--animation_duration) + var(--appear_duration));
  }



/* Tiles */
.grid-container {
    position: absolute;
    width: calc(84.6 * var(--vw));
    height: calc(84.6 * var(--vw));
    z-index: 1;
}

.grid-row {
    margin-bottom: calc(2.7 * var(--vw));
    width: calc(84.6 * var(--vw));
    height: calc(19.125 * var(--vw));
}

.final-row {
    margin-bottom: 0px;
}

.grid-cell {
    width: calc(19.125 * var(--vw));
    height: calc(19.125 * var(--vw));
    margin-right: calc(2.7 * var(--vw));
    float: left;
    border-radius: var(--border-radius-small);
    background: var(--muted-board-color);
    /* background: rgba(147, 152, 157, 0.2); */
}

.final-cell {
    margin-right: 0px;
}

