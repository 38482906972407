@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Dark-mode Colors */
* {
  --bg-color-0: #000000;
  --bg-color-1: #121212;
  --bg-color-2: #191919;
  --bg-color-3: #202020;  /* reserved for borders */
  --text-color: #ebebeb;

  --accent-color: #4B57C9;
} 
/* End of Dark-mode Colors */


/* Note: sizing is set for vw with a screen width of 94% (3% margin on each side) */
/* Page-wide settings */
* {
  box-sizing: border-box;

  --vw: 1vw;
  --vh: 1vh;
  /* --vh: calc(0.01 * var(--screen-height)); */
  
  --primary-color: var(--text-color);
  --background-color: var(--bg-color-0);

  --board-color: var(--bg-color-1);
  --muted-board-color: var(--bg-color-2);

  --border-color: var(--bg-color-3);

  --green: #029347;
  --greencolor: 2, 147, 71;
  --blue: #0453f2;
  --bluecolor: 4, 83, 242;
  --orange: rgb(225, 82, 0);
  --orangecolor: 225, 82, 0;
  --purple:#7d32ed;
  --purplecolor: 125, 50, 237;
  /* Colors currently not in use */
  /* --red: #FF1900; */
  /* --salmon: #FF6E30; */

  --border-radius: calc(3.5 * var(--vw));
  --border-radius-small: calc(1.5 * var(--vw));
  --border-radius-large: calc(6 * var(--vw));

  --border-width: calc(.2 * var(--vw));
  --border-width-small: calc(0.00 * var(--vw));
  --game-border-width: calc(1 * var(--vw));
  
  
  --animation_duration: 175ms;
  --appear_duration: 200ms;
  --appear_delay: var(--animation_duration);
}
  
@media screen and (min-width: 450px) {
  * {
    --vw: 4.5px;
  }
}

.App {
    
}

html,
body {
    margin: auto auto;
    float: none !important;
    max-width: 532px;
    background: var(--background-color);
    color: var(--primary-color);
    font-family: 'Poppins', 'Arimo', sans-serif;
    font-size: calc(3.24 * var(--vw));
    position: relative;
}

body {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.normal-icon {
  --dim: calc(5 * var(--vw));
  width: var(--dim);
  height: var(--dim);
}

.back-icon {
  --dim: calc(6 * var(--vw)) !important;
  width: var(--dim);
  height: var(--dim);
  margin-bottom: calc(.1 * var(--dim));
  margin-right: calc(0 * var(--dim));
}

.redo-icon {
  --dim: calc(4 * var(--vw)) !important;
  width: var(--dim);
  height: var(--dim);
  margin-bottom: calc(.1 * var(--dim));
  margin-right: calc(.3 * var(--dim));
}

.home-icon {
  --dim: calc(4 * var(--vw));
  width: var(--dim);
  height: var(--dim);
  margin-bottom: calc(.2 * var(--dim));
  margin-right: calc(.3 * var(--dim));
}

.settings-icon {
  --dim: calc(4 * var(--vw)) !important;
  width: var(--dim);
  height: var(--dim);
  margin-bottom: calc(.15 * var(--dim));
  margin-right: calc(.1 * var(--dim));
}

.check-icon {
  --dim: calc(5 * var(--vw)) !important;
  width: var(--dim);
  height: var(--dim);
  margin-bottom: calc(.1 * var(--dim));
  margin-right: calc(0 * var(--dim));
}

.icon-accent {
  color: var(--accent-color);
}



/* Current Colors */

.green {
  --current-color: var(--green);
  --current-color-values: var(--greencolor);
}

.blue {
  --current-color: var(--blue);
  --current-color-values: var(--bluecolor);
}

.orange {
  --current-color: var(--orange);
  --current-color-values: var(--orangecolor);
}

.purple {
  --current-color: var(--purple);
  --current-color-values: var(--purplecolor);
}

.outline {
  border-color: var(--current-color);
}

.background {
  background: var(--current-color);
}

.text {
  opacity: 1;
  color: var(--current-color);
}

/* Common Elements */
.custom-card {
  border-style: solid;
  border-width: var(--border-width);
  border-radius: var(--border-radius-small);
  border-color: var(--border-color);
  background-color: var(--board-color);
}

/* General settings */
h1 {
    font-size: calc(13.5 * var(--vw));
    font-weight: bold;
}

h2 {
    font-size: calc(5.04 * var(--vw));
    font-weight: bold;
    margin-top: calc(2.52 * var(--vw));
}

p {
    display: block;
    line-height: 1.5;
}

ul {
    margin-top: 0;
}

a:link {
    color: var(--pop-color-1);
    font-weight: bold;
}

a:visited {
    color: var(--pop-color-1);
    font-weight: bold;
}


/* Modifiers */

/* Center vertically and horizontally */
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.vertically-centered {
  display: flex;
  justify-content: center;
  height: 100%;
}

.horizontally-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.evenly-spaced {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}

.display-reset {
  display: block;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bolded {
  font-weight: 600;
}



/* Change the bootstrap behavior and add a new button */
.btn-outline-main-color {
  --bs-btn-color: var(--text-color);
  --bs-btn-bg: var(--bg-color-1);
  --bs-btn-border-color: var(--bg-color-3);
  --bs-btn-border-radius: calc(2.5 * var(--vw));

  --bs-btn-hover-color: var(--text-color);
  --bs-btn-hover-bg: var(--bg-color-2);
  /* --bs-btn-hover-border-color: var(--pop-color-2); */
  --bs-btn-focus-shadow-rgb: 108, 117, 125;

  --bs-btn-active-color: var(--text-color);
  --bs-btn-active-bg: var(--accent-color);
  --bs-btn-active-border-color: var(--accent-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

  --bs-btn-disabled-color: var(--text-color);
  --bs-btn-disabled-bg: var(--bg-color-1);
  --bs-btn-disabled-border-color: var(--border-color);
  --bs-gradient: none;
}

.navbar {
  padding-bottom: 0;
}

.navbar-brand {
  color: var(--primary-color) !important;
}

.card-header, .card-body {
    color: var(--border-color) !important;
}

/* Change the behavior of tabs */
.nav {
  --bs-nav-tabs-border-width: var(--border-width-small);
  --bs-nav-tabs-border-color: var(--border-color);
  --bs-nav-tabs-border-radius: var(--border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--border-color);
  --bs-nav-tabs-link-active-color: var(--primary-color);
  --bs-nav-tabs-link-active-bg: var(--board-color);
  --bs-nav-tabs-link-active-border-color: var(--border-color);
  --bs-nav-link-color: var(--primary-color);
}

/* change tab color */
.nav-link {
  color: var(--primary-color);
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link.active {
  border-bottom-color: var(--board-color) !important;
}

.tab-content {
  padding: calc(2 * var(--vw));
  background-color: var(--board-color);

  border-width: var(--border-width-small);
  border-style: solid;
  border-color: var(--border-color);
  border-radius:  0 0 var(--border-radius) var(--border-radius);
  border-top: none;
}

/* Modal Settings */
.modal {
  --bs-modal-bg: var(--bg-color-0);
  --bs-modal-border-color: var(--border-color);
  --bs-modal-border-width: var(--border-width-small);
  --bs-modal-border-radius: var(--border-radius);
  --bs-modal-header-border-color: var(--border-color);
  --bs-modal-header-border-width: var(--border-width-small);
  --bs-modal-footer-border-color: var(--border-color);
  --bs-modal-footer-border-width: var(--border-width-small);
}

.btn-close {
  display: none;
}

/* CSS for the loading pages */

.disabled {
  opacity: 0.5;
}

.title {
  width: 90%;
  margin-top: 30%;
  margin-bottom: 10%;
}

.start-menu-container {
  display: grid;
}

.start-button {
  margin-top: calc(20* var(--vw));
  margin-bottom: calc(5 * var(--vw));
}

.start-text {
  font-weight: bold;
  font-size: calc(12 * var(--vw));
}

.nav-box {
  width: calc(75 * var(--vw));
  height: calc(30 * var(--vw));
  padding: calc(3 * var(--vw));
}

.small-box {
  width: calc(30 * var(--vw));
  height: calc(15 * var(--vw));
}

.done-box {
  width: calc(25 * var(--vw));
  height: calc(13 * var(--vw));
}

.small-text {
  font-size: calc(4.5 * var(--vw));
}

.how-to-window {
  margin-top: calc(5 * var(--vh));
}

.how-to-box {
  width: calc(85 * var(--vw));
  height: calc(85 * var(--vh));
  padding: calc(5 * var(--vw));
  padding-bottom: 0;
}

.how-to-box-ios {
  height: calc(75 * var(--vh));
}

.how-to-container {
  height: 85%;
  overflow: auto;
}

.how-to-close {
  height: 15%;

}

.how-to-container::after {
  content: "";
  position: absolute;
  bottom: 14%; /* still not sure how to calculate this. Should depend on container height */
  left: 0;
  width: 100%;
  height: 15%; /* Adjust as needed */
  background: linear-gradient(transparent, var(--bg-color-0));
  pointer-events: none;
}

.menu-spacer {
  margin-bottom: calc(10 * var(--vw));
}

.menu-bar {
  margin-top: calc(2.5 * var(--vw));
  margin-bottom: calc(5 * var(--vw));
  width: calc(80 * var(--vw));
}

.menu-nav {
  width: calc(30 * var(--vw));
  height: calc(13 * var(--vw));
  font-size: calc(5 * var(--vw));
}

.menu-option {
  width: calc(80 * var(--vw));
  height: calc(3 * var(--vw));
  font-size: calc(4 * var(--vw));
}

.select-header {
  font-weight: bold;
  font-size: calc(7.5 * var(--vw));
}

.select-body {
  font-size: calc(3 * var(--vw));
  padding: 0 calc(5 * var(--vw));
}

.choose-text {
  font-weight: bold;
  font-size: calc(10 * var(--vw));
  color: var(--current-color);
}

.gap {
  margin-bottom: calc(5 * var(--vw));
}

.pop-box.left {
  align-self: flex-start;
}

.pop-box.right {
  align-self: flex-end;
}

.profile-img {
  
  width: 60%;
  margin-top: calc(2 * var(--vw));
  margin-bottom: calc(5 * var(--vw));
}
