/* Tile Creation Animations */
@keyframes appear {
    0% {
        transform: scale(0);
    }

    1% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes appear-subtle {
    0% {
        transform: scale(0);
    }

    1% {
        transform: scale(0.92);
    }

    70% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

.appear-animate {
    animation: appear-subtle 200ms ease;
    animation-fill-mode: backwards;
}

/* Tile animation to delete */
@keyframes delete-after-move {
    0% {
        visibility: visible;
    }
    99% {
        visibility: visible;
    }
    100% {
        visibility: hidden;
        z-index: 0;
    }
}

