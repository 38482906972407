@keyframes pop {
    0% {transform: scale(1.0, 1.0);}
    40% {transform: scale(1.05, 1.05);}
    100% {transform: scale(1.0, 1.0);}
}

@keyframes pop-easy {
    0% {transform: scale(1.0, 1.0);}
    40% {transform: scale(1.03, 1.03);}
    100% {transform: scale(1.0, 1.0);}
}


@keyframes swing-and-fall {
    0% { transform: rotate(0deg) translateY(0); z-index: 2; }
    20% { transform: rotate(-10deg) translateY(3.2vh) translateX(1vh); }
    30% { transform: rotate(-10deg) translateY(3.2vh) translateX(1vh); }
    100% { transform: rotate(20deg) translateY(40vh) translateX(2vh); ; z-index: 2; }
}